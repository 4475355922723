import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { updatePromotion } from "../../axiosHandle/promotionHandle";

const UpdateADPoster = ({
  showOffcanvas,
  handleCloseOffcanvas,
  selectedPromotionDetails,
  isUpdatedPromotion,
  setIsUpdatedPromotion,
}) => {
  const offcanvasStyle = {
    width: "365px",
    height: "145px",
    display: "flex",
    marginLeft: 18,
    marginTop: 20,
    flexDirection: "column",
  };
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 328 * 180; // Max size in pixels
  
      // Check file type
      if (!allowedTypes.includes(file.type)) {
        toast.error("Please upload a PNG or JPG/JPEG file.");
        return;
      }
  
      // Check image dimensions
      const img = new Image();
      img.onload = function () {
        console.log("Image width:", img.width);
        console.log("Image height:", img.height);
        if (img.width !== 328 || img.height !== 180) {
          toast.error("Please upload an image with dimensions 328x180 pixels.");
          return;
        } else {
          setSelectedFileName(file);
        }
      };
  
      img.src = URL.createObjectURL(file);
    }
  };

  
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(20, "Name must be at most 20 characters"),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedPromotionDetails.title,
      is_active: selectedPromotionDetails.is_active,
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const data = {
          title: values.name,
          ad_image: selectedFileName || selectedPromotionDetails.ad_image,
          is_active: values.is_active,
        };

        const updateData = await updatePromotion(
          selectedPromotionDetails.id,
          data
        );

        if (updateData) {
          setIsUpdatedPromotion(!isUpdatedPromotion);
          toast.success("Promotion updated successfully!");
          handleCloseOffcanvas();
        } else {
          console.error("Error while updating Promotion:", updateData.error);
        }
      } catch (err) {
        console.error("Error updating Promotion:", err);
        toast.error("An error occurred while updating promotion.");
      }

      setIsLoading(false);
    },
  });

  return (
    <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end" style={{ overflow: "auto" }}>
      <Offcanvas.Header closeButton style={{position: "relative", left: 320}}/>
      <form onSubmit={formik.handleSubmit}>
        <div style={offcanvasStyle} className="update-promotion-container" >
          <h5>Add Spot - 01</h5>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="error">{formik.errors.name}</div>
            )}
          </div>
          <div style={{ marginTop: 10 }}>
            <label>Ad Poster (328*180)</label>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
            {selectedFileName && (
              <span>{selectedFileName.name}</span>
            )}
          </div>
          <div>
            <label>Status</label>
            <input
              type="checkbox"
              name="is_active"
              checked={formik.values.is_active}
              onChange={formik.handleChange}
            />
            <span>{formik.values.is_active ? "Active" : "Inactive"}</span>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "10px" }}>
          <button className="btn btn-primary" type="submit" disabled={isLoading}>
            {isLoading ? <Loader /> : "Update Ad Poster"}
          </button>
        </div>
      </form>
    </Offcanvas>
  );
};

export default UpdateADPoster;
